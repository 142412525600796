import { IUploadPPA } from "@/models/FormData";
import { IPagination } from "@/models/Pagination";
import { IScopeOneScopeThreeDTO } from "@/models/ScopeOneScopeThreeEmissions";
import {
  IScopeTwoParameters,
  IScopeTwoPPADetailParameters,
  IScopeTwoPPADetail,
  IScopeTwoGridEmissionFactor,
} from "@/models/ScopeTwoEmissions";
import { ErrorResponse } from "@/services/axios/error";
import { S2RPAPI } from "@/services/s2rp";
import store from "@/store";
import { ElMessage } from "element-plus/lib/components/message";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import { formatPPADetailData } from "@/utils/helpers/buildDataFormat";
const name = "PPA";

if (store.state[name]) {
  store.unregisterModule(name);
}
@Module({ dynamic: true, name, namespaced: true, store })
class PPAModule extends VuexModule {
  private pagination = {} as IPagination;

  private errorUploadPPA = {} as ErrorResponse;
  private errorPPARequest = {} as ErrorResponse;
  private errorPPARequestDetail = {} as ErrorResponse;

  private PPARequestData = [] as IScopeOneScopeThreeDTO[];

  private isLoadingPPARequest = false;

  private isReadonlyFormPPA = false;

  private isLoadingPPARequestDetail = false;

  private PPARequestDataDetail = {} as IScopeTwoPPADetail;

  private PPADataFromFacility = {} as IScopeTwoGridEmissionFactor;
  get PPARequestDataGetter() {
    return this.PPARequestData;
  }

  get PPADataFromFacilityGetter() {
    return this.PPADataFromFacility;
  }

  get isLoadingPPARequestGetter() {
    return this.isLoadingPPARequest;
  }

  get isReadonlyFormPPAGetter() {
    return this.isReadonlyFormPPA;
  }

  get PPARequestDataDetailGetter() {
    return this.PPARequestDataDetail;
  }

  get isLoadingPPARequestDetailGetter() {
    return this.isLoadingPPARequestDetail;
  }

  @Mutation
  togglePPAButtonType(isReadonlyFormPPA = false) {
    this.isReadonlyFormPPA = isReadonlyFormPPA;
  }

  @Mutation
  emptyPPARequest() {
    const { Collection, ...rest } = {} as any;
    this.PPARequestData = Collection;
    this.pagination = rest;
  }

  @Mutation
  emptyPPARequestDetail() {
    this.PPARequestDataDetail = {} as IScopeTwoPPADetail;
  }

  @Mutation
  uploadScopeThreeFailure(error: ErrorResponse) {
    this.errorUploadPPA = error;
  }
  @Mutation
  uploadScopeThreeSuccess() {
    ElMessage.success("Upload PPA success.");
    this.errorUploadPPA = {} as ErrorResponse;
  }
  @Action({ rawError: true })
  async uploadPPAAction(payload: IUploadPPA) {
    const S2RPAPIService = new S2RPAPI();
    const { error } = await S2RPAPIService.uploadPPA(payload);
    if (error) {
      this.uploadScopeThreeFailure(error);
      return;
    }
    this.uploadScopeThreeSuccess();
  }

  @Mutation
  LoadingPPARequest(loading: boolean) {
    this.isLoadingPPARequest = loading;
  }
  @Mutation
  getPPARequestFailure(error: ErrorResponse) {
    this.errorPPARequest = error;
    this.isLoadingPPARequest = false;
  }
  @Mutation
  getPPARequestSuccess(data: any) {
    const { Collection, ...rest } = data;
    this.errorPPARequest = {} as ErrorResponse;
    this.PPARequestData = Collection;
    this.pagination = rest;
    this.isLoadingPPARequest = false;
  }
  @Action({ rawError: true })
  async getPPARequestAction(payload: IScopeTwoParameters) {
    this.LoadingPPARequest(true);
    const S2RPAPIService = new S2RPAPI();
    const { data, error } = await S2RPAPIService.getPPARequest(payload);
    if (error) {
      this.getPPARequestFailure(error);
      return;
    }
    this.getPPARequestSuccess(data);
  }

  @Mutation
  LoadingPPARequestDetail(loading: boolean) {
    this.isLoadingPPARequestDetail = loading;
  }
  @Mutation
  getPPARequestDetailFailure(error: ErrorResponse) {
    this.errorPPARequestDetail = error;
    this.isLoadingPPARequestDetail = false;
    this.PPARequestDataDetail = {} as IScopeTwoPPADetail;
  }
  @Mutation
  getPPARequestDetailSuccess(data: IScopeTwoPPADetail) {
    this.errorPPARequestDetail = {} as ErrorResponse;
    this.PPARequestDataDetail = formatPPADetailData(data);
    this.isLoadingPPARequestDetail = false;
  }
  @Action({ rawError: true })
  async getPPARequestDetailAction(payload: IScopeTwoPPADetailParameters) {
    this.LoadingPPARequestDetail(true);
    const S2RPAPIService = new S2RPAPI();
    const { data, error } = await S2RPAPIService.getPPARequestDetail(payload);
    if (error) {
      this.getPPARequestDetailFailure(error);
    } else {
      this.getPPARequestDetailSuccess(data);
    }
  }

  @Mutation
  async setPPADataFromFacility(data: IScopeTwoGridEmissionFactor) {
    this.PPADataFromFacility = data;
  }
}

export default getModule(PPAModule);
